import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

interface dialogData {
	title: string;
	data: any;
	oldCode: string;
}

@Component({
	selector: 'app-password-dialog-modal',
	templateUrl: './custom-password-dialog.component.html',
	styleUrls: ['./custom-password-dialog.component.scss']
})
export class ModalPasswordDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public password: string = '';
	public oldpassword: string = '';
	public confirmpassword: string = '';
	public showNewPassword: boolean = false;
	public showConfirmPassword: boolean = false;
	public passwordForm: FormGroup;
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, private formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<ModalPasswordDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: dialogData) {
		// console.log('UserViewDialogData', data);
	}
	ngOnInit() {
		this.passwordForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")]],
			confirmpassword: ['', [Validators.required, this.checkPasswords]]
		});
	}

	private checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		if (this.passwordForm) {
			let pass = this.passwordForm.controls.password.value;
			let confirmPass = this.passwordForm.controls.confirmpassword.value;
			if ((pass && pass === confirmPass) || ((confirmPass && confirmPass.length === 0) || (pass && pass.length === 0)))
				return null;
			else {
				return { notSame: true };
			}
		} else {
			return { notSame: true };
		}

	}
	public saveData() {
		if (this.passwordForm.controls.password.value !== '') {
			if (this.passwordForm.controls.password.value === this.passwordForm.controls.confirmpassword.value) {
				if (this.passwordForm.controls.password.value.length < 8 || this.passwordForm.controls.password.value.length > 64) {
					this.layoutUtilsService.showNotification('Password should be of at least 8 characters and maximum 64 characters', this.translate.instant('Dismiss'));
					return;
				}
				if (!this.loading) {
					this.loading = true;
					this.errorMessage = '';
					this.requestService.editUserPassword(this.data.oldCode, this.passwordForm.controls.password.value, (data, error) => {
						if (error) {
							this.errorMessage = error;
							this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
						}
						this.loading = false;
						if (data) {
							this.layoutUtilsService.showNotification(this.translate.instant('Password Changed'), this.translate.instant('Dismiss'));
							this.closeModal(true);
						}
					});
				}
			} else {
				this.layoutUtilsService.showNotification(this.translate.instant('The passwords don\'t match'), this.translate.instant('Dismiss'));
			}
		} else {
			this.layoutUtilsService.showNotification(this.translate.instant('Fill in the fields'), this.translate.instant('Dismiss'));
		}
	}
	//   public saveData() {
	//       if (this.password !== '' && this.confirmpassword !== '') {
	// 			if (this.password.length < 8 || this.password.length > 64) {
	// 				this.layoutUtilsService.showNotification('Password should be of at least 8 characters and maximum 64 characters', this.translate.instant('Dismiss'));
	// 				return;
	// 			}
	// 			if (this.password === this.confirmpassword) {
	// 	        if (!this.loading) {
	// 	            this.loading = true;
	// 	            this.errorMessage = '';
	// 	            this.requestService.editUserPassword(this.data.oldCode, this.password, (data, error) => {
	// 	              if (error) {
	// 	                this.errorMessage = error;
	// 	                this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
	// 	              }
	// 	              if (data) {
	// 	  							this.layoutUtilsService.showNotification(this.translate.instant('Password Changed'), this.translate.instant('Dismiss'));
	// 	                this.closeModal(true);
	// 	              }
	// 	              this.loading = false;
	// 	            });
	// 	        }
	// 	      }else{
	// 	        this.layoutUtilsService.showNotification(this.translate.instant('The passwords don\'t match'), this.translate.instant('Dismiss'));
	// 	      }
	//       }else{
	//         this.layoutUtilsService.showNotification(this.translate.instant('Fill in the fields'), this.translate.instant('Dismiss'));
	//       }
	//   }
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
