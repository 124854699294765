import { Component, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable , interval, fromEvent } from 'rxjs';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, StoreService, LoggerService, LoaderService, MenuConfigService, LayoutUtilsService } from './shared/services';
import { environment } from '../environments/environment';
import { SnackBarComponent } from './shared/components/snack-bar/snack-bar.component';
import { MenuConfig } from './menu.config';
import { ErrorEntityDialogComponent } from './shared/components/modals';
import { guid } from './shared/helpers';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public subscriptions: any[] = <any>[];
    public uniqueIDIdentifier: string = guid();
    public uniqueID: string = JSON.parse(JSON.stringify(this.uniqueIDIdentifier));
    public showLoader: boolean = false;
    public showError: boolean = true;
    constructor(private meta:Meta, private title: Title, private loaderService: LoaderService, public snackBar: MatSnackBar, private translate: TranslateService, private requestService: RequestService, private logger: LoggerService, private router: Router, private dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private storeService: StoreService, private renderer: Renderer2) {
        translate.setDefaultLang('en');
    }
    ngOnInit() {
      this.subscriptions.push(
        this.router.events.subscribe( (event: any) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                this.loaderService.display(false);
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                // window.location.reload(); // uncomment later
                console.log('NavigationError:', event.error);
            }
        })
      );
      this.subscriptions.push(
        this.logger.errorObject.subscribe((error) => {
          if (error) {
            // console.log('Global Error: ', error);
            // this.loaderService.display(false);
            if(this.showError){
              this.showError = false;
              this.openAlert('An Error occured:' + error);
            }
          }
        })
      );
    }
    ngOnDestroy() {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public openAlert(message, title = 'Error') {
      const _title: string = title;
  		const _description: string = message;

  		const dialogRef = this.layoutUtilsService.errorElement(_title, _description);
  		dialogRef.afterClosed().subscribe(res => {
  			if (!res) {
  				return;
  			}
        window.location.reload();
  		});
    }
}
