import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CustomSelectDialogComponent } from '../../../shared/components/custom-select-dialog/custom-select-dialog.component';
import { MatDialog } from '@angular/material';

export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DialogData {
	dataType: string;
	dataTypeTitle: string;
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-dialog-modal',
	templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
	public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = true;
  public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];
	constructor(private translate: TranslateService, private changeDetectorRefs: ChangeDetectorRef,
		private requestService: RequestService, public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData) {
			console.log('DialogData', data);
	}
	ngOnInit() {
		this.buildSetting();
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
      this.data.modalSetting.fields = [];
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
          let newFields = data.results.fields;
          let idx = 0;
          if(this.data.modalSetting.hasOwnProperty('customSettings')){
            for( let fld of newFields){
                if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)){
                  newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
                }
                idx++;
            }
          }
					this.data.modalSetting.fields = newFields;
					if(this.data.data.hasOwnProperty('_id')){
						this.loadData();
					}else{
						this.data.data = this.getEmptyObject();
					}
				} else {
					this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
				}
			});
		}
	}
	public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      if(this.data.modalSetting.hasOwnProperty('useOrgId')){
        dataId = dataId + '/' + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = data.results;
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    // console.log('setRoleReferenceAttribute', parentId, id, val);
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = [{_id: val._id, name: val.name }];
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try{
      this.data.data[id] = val.toISOString();
    }catch(e){
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { _id: data._id};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action' ) {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        }else if (col.type === 'reference') {
          if(col.reference.kind === 'multiple'){
            if(data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          }else{
            if(data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated ) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if(col.reference.kind === 'multiple'){
            // console.log('col.name', col.name, data[col.name] );
            if (data && data[col.name] && data[col.name].length === 0) {
              return false;
            }
          }else{
            if (data && data[col.name] && data[col.name] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        }else if(col.type === 'tags'){
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else if(col.type === 'email'){
          if (data && (data[col.name].length === 0 || data[col.name] === undefined || !this.isValid(data[col.name]))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }else{
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  public saveData(type) {
		// console.log('saveData', this.data.data);
		// console.log('this.data.modalSetting', this.data.modalSetting);
		// console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (this.data.modalSetting.target === 'self') {
      if (!this.loading) {
        if (this.validateObject(this.data.data)) {
          this.loading = true;
          //  this.loaderService.display(true);
          let useOrgId = false;
          if(this.data.modalSetting.hasOwnProperty('useOrgId')){
            useOrgId = this.data.modalSetting['useOrgId'];
          }
          this.errorMessage = '';
          this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            if (data) {
              if(type === 'create'){
           			this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
              }else if (type === 'edit'){
  							this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
              }
              this.closeModal({action: 'refresh'});
            }
            this.loading = false;
          }, useOrgId);
        } else {
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to set all mandatory fields'), this.translate.instant('Dismiss'));
        }
      }
    } else if (this.data.modalSetting.target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({action: type, dataType: this.data.dataType, dataTypeTitle: this.data.dataTypeTitle, modalSetting: this.data.modalSetting, data: this.getCleanObject(this.data.data)});
      } else {
				this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({action: action, data: this.data.data, value: data});
      } else {
				this.layoutUtilsService.showNotification(this.translate.instant('Error:') + this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    } else {
      console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
	private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if(col.reference.kind === 'multiple'){
            if(col.name === 'resources'){
              newObj[col.name] = [{_id: '', name: ''}];
            }else{
              newObj[col.name] = [];
            }
          }else{
            newObj[col.name] = { _id:'', name: ''};
          }
        } else if(col.type === 'boolean') {
          newObj[col.name] = false;
        } else if(col.type === 'picturearray') {
          newObj[col.name] = [];
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('customSettings')) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  private isValid(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
  public addPictures(id, url) {
    if(this.data.data.hasOwnProperty(id)){
      this.data.data[id].push({url: url, type: 'link', link: ""});
    }else{
      this.data.data[id] = [{url: url, type: 'link', link: ""}];
    }
  }
  public setPictureArrayType(idx, id, val) {
      this.data.data[id][idx]['type'] = val;
      this.data.data[id][idx]['link'] = '';
  }
  editCustomSelectDialog(dataType, dataTypeTitle, idx, id){
    const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
        width: '600px',
        data: {
          title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
          dataType: dataType,
          dataTypeTitle: dataTypeTitle,
          data: this.data.data[id][idx]['link'],
        }
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result.hasOwnProperty('_id')){
          this.data.data[id][idx]['link'] = result['_id'];
        }else{
          this.data.data[id][idx]['link'] = '';
        }
      }
    });
  }
  public deletePictures(id, index) {
    this.data.data[id].splice(index, 1);
  }
  /**
  *  @param target: trigger event
  *
  *  trigger read files browsed files
  */
  onBrowseFiles(id, target: any): void {
      this.readFiles(id, target.files);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(id, files, index = 0 ): void {
    // let reader = new FileReader();
      if (index in files) {
        let currentFile = {error: false, text: files[index].name, id: files[index].id, originalFile: files[index], source_url: null};
        let fileExt = files[index].name.split('.').pop();
        const max_size = 5000000;
        if (files[index].size > max_size) {
          this.layoutUtilsService.showNotification( this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'Mb', this.translate.instant('Dismiss'));
        }else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
          currentFile.error = true;
        } else {
          this.requestService.onUploadPictureByBanner(currentFile)
          .subscribe(
                  (results: any) => {
                    console.log('results', results);
                    if (results['status']) {
                      currentFile.source_url = results['results'].link;
                      this.addPictures(id, results['results'].link);
											this.layoutUtilsService.showNotification( this.translate.instant('Successfully Uploaded'), this.translate.instant('Dismiss'));
                    }else {
                      currentFile.error = true;
											this.layoutUtilsService.showNotification(this.translate.instant('Error:') + results['message'], this.translate.instant('Dismiss'));
                    }
										// this.myInputVariable.nativeElement.value = "";
										this.changeDetectorRefs.detectChanges();
                    // this.currentFile = currentFile;
                  },
                  error => {
                    console.log('Error uploading file.', error);
                    currentFile.error = true;
										this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Error uploading file.') , this.translate.instant('Dismiss'));
										this.changeDetectorRefs.detectChanges();
                  }
          );
        }
      } else {
        this.changeDetectorRefs.detectChanges();
      }
  }
  readFile(file, reader, callback): void {
          reader.onload = () => {
                  callback(reader.result);
          }
          reader.readAsDataURL(file);
  }
}
