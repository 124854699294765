import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { GlobalService } from './services/global.service';

import { AlertActionEntityDialogComponent, ActionNotificationComponent, ConfirmInputEntityDialogComponent, AlertComponent, DeleteEntityDialogComponent, FetchEntityDialogComponent, UpdateStatusDialogComponent, AlertEntityDialogComponent, LoadingScreenComponent, ModalViewDialogComponent, EnumViewComponent, ErrorEntityDialogComponent, ConfirmEntityDialogComponent, ModalDialogComponent, CustomSelectComponent, CustomTagsComponent, ConfirmSelectEntityDialogComponent } from './components';

import { CustomSelectAutocompleteComponent, CustomSelectCreateAutocompleteComponent, CustomMultipleSelectComponent, CustomSelectCreateAutocompleteDialogComponent, ModalPreviewDialogComponent, CustomSelectDialogComponent, SnackBarComponent, ModalPasswordDialogComponent, CustomDateTimeComponent } from './components';
import { ClickOutSideDirective } from './directives';

import { Utils } from './helpers/utils';

import { CKEditorModule } from 'ngx-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FileModalDialogComponent } from './components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { SimpleAlertEntityDialogComponent } from './components/modals/simple-entity-dialog/simple-entity-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        ImageCropperModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false })
    ],
    providers: [
        // GlobalService
        Utils
    ],
    entryComponents: [
        AlertActionEntityDialogComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalViewDialogComponent,
        EnumViewComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        CustomSelectComponent,
        CustomTagsComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        CustomSelectDialogComponent,
        SnackBarComponent,
        ModalPasswordDialogComponent,
        CustomDateTimeComponent
    ],
    declarations: [
        AlertActionEntityDialogComponent,
        EnumViewComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        CustomSelectComponent,
        CustomTagsComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        SnackBarComponent,
        ModalPasswordDialogComponent,
        CustomDateTimeComponent
    ],
    exports: [
        AlertActionEntityDialogComponent,
        ActionNotificationComponent,
        ConfirmInputEntityDialogComponent,
        ConfirmSelectEntityDialogComponent,
        ModalViewDialogComponent,
        AlertComponent,
        DeleteEntityDialogComponent,
        ErrorEntityDialogComponent,
        ConfirmEntityDialogComponent,
        SimpleAlertEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        AlertEntityDialogComponent,
        EnumViewComponent,
        LoadingScreenComponent,
        ModalDialogComponent,
        FileModalDialogComponent,
        CustomSelectComponent,
        CustomTagsComponent,
        CustomSelectAutocompleteComponent,
        CustomSelectCreateAutocompleteComponent,
        CustomMultipleSelectComponent,
        CustomSelectCreateAutocompleteDialogComponent,
        ModalPreviewDialogComponent,
        CustomSelectDialogComponent,
        ClickOutSideDirective,
        SnackBarComponent,
        ModalPasswordDialogComponent,
        CustomDateTimeComponent
    ]
})
export class LayoutComponentModule { }
