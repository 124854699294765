import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmptyComponent } from './empty/empty.component';

const routes: Routes = [
    {
        path: '',
        component: EmptyComponent
        // loadChildren: './registration/registration.module#RegistrationModule'
    },
    {
        path: ':orgType/:orgId/:appId/:locId/:lang',
        loadChildren: './registration/registration.module#RegistrationModule'
    },
    {
        path: 'login/:orgType/:orgId/:appId/:locId/:lang',
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'validate/:orgType/:orgId/:appId/:locId/:lang',
        loadChildren: './validate/validate.module#ValidateModule'
    },
    { path: 'empty', component: EmptyComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
